import { Button } from "@/components/ui/button"
import {Link} from "react-router-dom"
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background text-center px-4">
      <h1 className="text-4xl font-bold mb-4">Oops! Something went wrong</h1>
      <p className="text-xl text-muted-foreground mb-8">
        We're sorry, but an unexpected error has occurred.
      </p>
      <div className="max-w-md text-center mb-8">
        <p className="text-muted-foreground">
          Our team has been notified and we're working to fix the issue. 
          Please try again later or return to the home page.
          Error: {error?.message}
        </p>
      </div>
      <Button asChild>
        <Link to="/">
          Return to Home
        </Link>
      </Button>
    </div>
  )
}