import { BicepsFlexed, Clipboard, SwatchBook } from "lucide-react";

const Features = () => {
    return (
      <section className="py-10 pt-0 bg-gray-50">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">

              <div className="px-5 py-4 bg-white lg:mt-20 lg:p-16 shadow-lg rounded-lg">
                    <div className="text-center pb-16">
                        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Features you'll love</h2>
                    </div>
                  <div className="grid grid-cols-1 gap-12 lg:gap-16 sm:grid-cols-2">
                      <div className="flex items-start">
                          <Clipboard className="w-16 h-16 text-fuchsia-600" style={{strokeWidth: 1}}/>
                          <div className="ml-5">
                              <h3 className="text-lg font-semibold text-black">Clipboard Automation</h3>
                              <p className="mt-4 text-base text-gray-600">Automatically paste copied data into Google Sheets.</p>
                          </div>
                      </div>

                      <div className="flex items-start">
                          <BicepsFlexed color="#C026D3" className="w-16 h-16 text-fuchsia-600" style={{strokeWidth: 1}}/>
                          <div className="ml-5">
                              <h3 className="text-lg font-semibold text-black">Works Seamlessly</h3>
                              <p className="mt-4 text-base text-gray-600">No more toggling between tabs—stay focused.</p>
                          </div>
                      </div>

                      <div className="flex items-start">
                          <svg className="flex-shrink-0 w-16 h-16 text-fuchsia-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                              />
                          </svg>
                          <div className="ml-5">
                              <h3 className="text-lg font-semibold text-black">Perfect for Ecommerce, Research, and More</h3>
                              <p className="mt-4 text-base text-gray-600">Designed to help you scrape competitor data, research findings, or marketing insights.</p>
                          </div>
                      </div>

                      <div className="flex items-start">
                          <SwatchBook className="h-16 w-16 text-fuchsia-600 flex-shrink-0" style={{strokeWidth: 1}}/>
                          <div className="ml-5">
                              <h3 className="text-lg font-semibold text-black">Easy to Use</h3>
                              <p className="mt-4 text-base text-gray-600">No complicated setup—just install a chrome plugin, allow access and begin!</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
}

export default Features;