import { Button } from '@/components/ui/button'

function CTA(){
  return (
    <section className="py-4 pb-12 bg-gray-50">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">

              <div className="px-5 py-8 mt-12 bg-white lg:mt-20 lg:p-16 space-y-8 text-center shadow-lg rounded-lg">
                <div className="text-center">
                    <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Get on the waitlist!</h2>
                </div>
                <p className="text-center">
                Up to #100 on the waitlist get access to the v1 of the CopytoSheets Chrome extension. Claim your position now! 
                </p>
                <div className="relative inline-flex group">
                    <div
                        className="absolute duration-1000 rotate-180 transitiona-all opacity-70 -inset-px rounded-xl blur-lg filter group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200"
                        style={{background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"}}
                    ></div>

                    <a
                        href="#"
                        title=""
                        className="relative inline-flex items-center justify-center py-3.5 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent px-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-opacity-90 rounded-xl"
                        role="button"
                    >
                        Sign Up for Early Access
                    </a>
                </div>
                <p className='text-center'>
                  <b>Waitlist closes on 15 Oct 2024.</b>
                </p>
              </div>
          </div>
      </section>
  )
}

export default CTA