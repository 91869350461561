import { TypographyH3 } from '@/components/ui/typography/TypographyH1';
//@ts-ignore
import { Helmet } from 'react-helmet';

const Hero = () => {        
    return (
      <div className="relative bg-gray-50">
    <div className="absolute bottom-0 right-0 overflow-hidden lg:inset-y-0">
        <img className="w-auto h-full" src="https://d33wubrfki0l68.cloudfront.net/1e0fc04f38f5896d10ff66824a62e466839567f8/699b5/images/hero/3/background-pattern.png" alt="" />
    </div>

    <header className="relative py-4 md:py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
                <div className="flex-shrink-0">
                  <TypographyH3 className="transform-uppercase slant-line-container">COPY TO SHEETS</TypographyH3>
                </div>
            </div>
        </div>
    </header>

    <section className="relative py-12 sm:py-16 lg:pt-20 lg:pb-36">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 sm:gap-y-20 xl:grid-cols-6">
                <div className="text-center xl:col-span-3 lg:text-left md:px-16 lg:px-0">
                    <div className="max-w-sm mx-auto sm:max-w-md md:max-w-full">
                        <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-4xl sm:leading-tight lg:text-4xl lg:leading-tight font-pj">Streamline Data Entry Directly into Google Sheets</h1>

                        <div className="mt-8">
                            <p className="mt-4 text-lg text-gray-900 lg:mt-0 font-pj">Forget manual copy-pasting. Use CopyToSheets to automate data transfers from web pages to Google Sheets while you stay productive.</p>
                            <p className="mt-4 text-lg text-gray-900 font-pj">Up to #100 on the waitlist get access CopytoSheets Chrome extension. Claim your position now!</p>
                        </div>
                    </div>

                    {/* <div className="mt-8 sm:flex sm:items-center sm:justify-center lg:justifylg:mt-12"> */}
                    <div id="getWaitlistContainer" data-waitlist_id="21035" data-widget_type="WIDGET_1"></div>
                    <Helmet>
                        <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
                        <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
                    </Helmet>
                    {/* </div> */}
                </div>

                <div className="xl:col-span-3 p-4 sm:p-0 lg:p-4">
                  <iframe className='mx-auto w-full' width="560" height="315" src="https://www.youtube.com/embed/dZpU1OoKt2c?si=SrgT39eRQYG6tnSc" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    </section>
    </div>

    )
}
export default Hero;