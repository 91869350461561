import { cn } from "@/lib/utils"

export function TypographyH1({children}: any) {
  return (
    <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
      {children}
    </h1>
  )
}

export function TypographyH2({children}: any) {
  return (
    <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
      {children}
    </h2>
  )
}
export function TypographyH3({children, className}: any) {
  return (
    <h3 className={cn("scroll-m-20 text-2xl font-semibold tracking-tight", className)}>
      {children}
    </h3>
  )
}

export function TypographyH4({children}: any) {
  return (
    <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
      {children}
    </h4>
  )
}

export function TypographyH5({children, className}: any) {
  return (
    <h5 className={cn("scroll-m-20 text-l font-semibold tracking-tight", className)}>
      {children}
    </h5>
  )
}

export function TypographyP({children, className}: any) {
  return (
    <p className={cn("leading-7 [&:not(:first-child)]:mt-6", className)}>
      {children}
    </p>
  )
}

export function TypographyInlineCode({children}: any) {
  return (
    <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
      {children}
    </code>
  )
}


export function TypographyBlockquote({children}: any) {
  return (
    <blockquote className="mt-6 border-l-2 pl-6 italic">
      {children}
    </blockquote>
  )
}

export function TypographyLead({children}: any) {
  return (
    <p className="text-xl text-muted-foreground">
      {children}
    </p>
  )
}

export function TypographyLarge({children}: any) {
  return <div className="text-lg font-semibold">{children}</div>
}

export function TypographySmall({children}: any) {
  return (
    <small className="text-sm font-medium leading-none">{children}</small>
  )
}

export function TypographyMuted({children}: any) {
  return (
    <p className="text-sm text-muted-foreground">{children}</p>
  )
}
