import Content from "@/layout/site/content"
import CTA from "@/layout/site/cta"
import Features from "@/layout/site/features"
import Hero from "@/layout/site/hero"

const content = [
  { 
    heading: 'Problem Statement', 
    subHeading: 'Tired of Switching Tabs and Manually Copying Data?',
    description: `If you're doing competitor analysis, gathering research, or just copying data from multiple pages, you know how tedious it can be. Switching between tabs, copying one field at a time, and then manually pasting it into a spreadsheet wastes time and breaks your focus.`
  },
  {
    heading: 'Solution Overview',
    subHeading: 'Clipboard Automation for Effortless Data Scraping',
    description: `With CopyToSheets, you can fill your Google Sheets directly from your clipboard—no need to leave the tab you're working on. Whether it's product names, dimensions, or prices, CopyToSheets handles the heavy lifting for you.`
  },
  {
    heading: 'How It Works',
    subHeading: 'Simple & Efficient in 3 Steps',
    description: <>
      <ol className="list-disc list-inside pl-8 space-y-2">
        <li><b>Select the Data </b> on any page you're working on.</li>
        <li><b>Copy It to Clipboard </b> — whether it's text, dimensions, or pricing details.</li>
        <li><b>Automatically Fill </b> your Google Sheets in the background without switching tabs.</li>
      </ol>
    </>
  }
]

const whyEarly = [
  {
    heading: 'Why Join the Waitlist?',
    subHeading: 'Get Early Access to Clipboard Automation',
    description: <>
    <ul className="list-disc list-inside pl-8 space-y-2">
      <li><b>Exclusive Features:</b>  Be the first to experience clipboard automation.</li>
      <li><b>Direct Feedback:</b> — Help shape the future of CopyToSheets with your input.
      </li>
      <li><b>Priority Support:</b> Get help from the team during the early access phase.</li>
    </ul>
  </>
  },
  {
    heading: 'Closing Note',
    subHeading: 'Say Goodbye to Manual Data Entry',
    description: `Don't waste time on repetitive tasks. Let CopyToSheets handle the busywork so you can focus on the bigger picture.`
  }
]

function HomeSite() {
  return <div className="bg-gray-50">
    <Hero />
    <Content content={content}/>
    <Features />
    <CTA />
    <Content content={whyEarly}/>
  </div>
}

export default HomeSite