function Content({content}: any) {
  return <section className="relative py-4">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        {
          content.map((item: any, index: number) => {
            return <div key={index} className="mb-20">
              <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-4xl sm:leading-tight lg:text-4xl lg:leading-tight font-pj">{item.heading}</h1>
              <p className="mt-4 text-lg pt-4 text-gray-900 lg:mt-0 font-pj mb-2"><b>{item.subHeading}</b></p>
              <p className="mt-4 text-lg text-gray-900 lg:mt-0 font-pj">{item.description}</p>
            </div>
          })
        }


      </div>
    </section>
}

export default Content